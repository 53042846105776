import { onError } from '@apollo/client/link/error';
import { addNotification } from '@quotalogic/notifications/store';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // if (networkError) {
  //   addNotification({
  //     message: networkError.message,
  //     level: 'ERROR',
  //   });
  // }

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.message && typeof window !== 'undefined') {
        if (err.extensions?.code === 'NOT_AUTHORIZED' && !window.location.pathname.includes('public')) {
          // reload page if not authorized. Redirect to login page on the server side.
          window.location.reload();
        } else {
          // eslint-disable-next-line no-continue
          if (err.extensions?.code === 'WCA_INHERITOR_EXISTS') continue;
          addNotification({
            message: err.message,
            level: err.extensions?.type as 'ERROR' | 'WARNING' | undefined ?? 'ERROR',
            code: err.extensions?.code as string | undefined,
          });
        }
      }
    }
  }
});

export default errorLink;
